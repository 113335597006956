import { PureComponent } from "react";
import UpgradeIcon from "components/upgrade-icon/index";
import AltCTA from "components/buttons/alternate/index";
import Condition from "components/feed/filter-rules/condition/index";
import { FaPlus } from "react-icons/fa";
import {
  RuleContainer,
  TooltipText,
  TextButton,
} from "constants/styled-components/index";
import { connect } from "react-redux";
import moment from "moment";
import styled, { withTheme } from "styled-components";
import Tooltip from "components/tooltip/index";
import { handleKeyboardClick } from "shared/helpers/handleKeyboardClick";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;
const FilterButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 0.5em;
  border-top: 1px solid ${(props) => props.theme.grey5};
`;
const AddRule = styled.div`
  animation: fadeIn 1s;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1em;
`;
const ButtonContainer = styled.div`
  min-width: 15em;
  display: flex;
  justify-content: center;
`;
const Hr = styled.hr`
  margin: 0.5em 1em;
  border-color: ${(props) => props.theme.grey5};
`;
const H3 = styled.h3`
  color: ${(props) => props.theme.grey2};
  margin: 0;
  width: ${(props) => (props.width ? props.width : "auto")};
  font-weight: bold;
  padding: 0 0.5em;
  display: flex;
  text-transform: uppercase;
  text-align: ${(props) => (props.center ? "center" : "left")};
  align-items: center;
  justify-content: center;
`;

class Rule extends PureComponent {
  constructor(props) {
    super(props);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.removeCondition = this.removeCondition.bind(this);
    this.addRule = this.addRule.bind(this);
  }

  componentDidMount() {
    this.loadUserOptions();
  }

  loadUserOptions() {
    if (
      this.props.dataUser &&
      this.props.dataUser.plan_id > 1 &&
      !this.props.dataUser.team
    ) {
      this.props.apiCall({
        method: "GET",
        component: "USER_TEAMMATES",
        route: "private_collection/users_for_photo_tags",
      });
    }
  }

  addRule() {
    this.props.addRule();
  }

  setStartDate(ruleIndex, conditionIndex, date) {
    this.props.setData({
      type: this.props.alerts
        ? "ALERT_CONDITION_SET_START_DATE"
        : this.props.settings
        ? "AUTOMATION_CONDITION_SET_START_DATE"
        : "FILTER_CONDITION_SET_START_DATE",
      data: {
        ruleIndex: ruleIndex,
        conditionIndex: conditionIndex,
        date: date,
      },
    });
  }
  setEndDate(ruleIndex, conditionIndex, date) {
    const newDate = moment(date).set({ hour: 23, minute: 59 });
    this.props.setData({
      type: this.props.alerts
        ? "ALERT_CONDITION_SET_END_DATE"
        : this.props.settings
        ? "AUTOMATION_CONDITION_SET_END_DATE"
        : "FILTER_CONDITION_SET_END_DATE",
      data: {
        ruleIndex: ruleIndex,
        conditionIndex: conditionIndex,
        date: newDate.toDate(),
      },
    });
  }

  addCondition(ruleIndex) {
    this.props.setData({
      type: this.props.alerts
        ? "ADD_EMPTY_ALERT_CONDITION"
        : this.props.settings
        ? "ADD_EMPTY_AUTOMATION_CONDITION"
        : "ADD_EMPTY_FILTER_CONDITION",
      data: {
        ruleIndex: ruleIndex,
      },
    });
  }

  removeCondition(ruleIndex, conditionIndex) {
    this.props.setData({
      type: this.props.alerts
        ? "REMOVE_ALERT_CONDITION"
        : this.props.settings
        ? "REMOVE_AUTOMATION_CONDITION"
        : "REMOVE_FILTER_CONDITION",
      data: {
        ruleIndex: ruleIndex,
        conditionIndex: conditionIndex,
      },
    });
  }
  render() {
    const isFreePlan = this.props.dataUser && this.props.dataUser.plan_id == 1;
    const existingOptions =
      this.props.rule &&
      this.props.rule.length > 0 &&
      this.props.rule.map((x) => x.match);
    return (
      <Wrapper>
        <RuleContainer
          outline={this.props.static && !this.props.alerts}
          transparent={this.props.center}
        >
          {this.props.rule &&
            this.props.rule.length > 0 &&
            this.props.rule.map((condition, conditionIndex) => (
              <Condition
                center={this.props.center}
                condition={condition}
                settings={this.props.settings}
                conditionIndex={conditionIndex}
                existingOptions={existingOptions}
                static={this.props.static}
                ruleIndex={this.props.ruleIndex}
                key={`filter-condition-${conditionIndex}-${this.props.ruleIndex}`}
                lastConditionIndex={this.props.rule.length - 1}
                setStartDate={this.setStartDate}
                setEndDate={this.setEndDate}
                removeCondition={this.removeCondition}
                pageId={
                  condition.match === "locations" &&
                  this.props.rule.find(condition => condition.match === "retailers")
                    ?.item?.map(item => item.id)
                    ?.join(",")
                }
              />
            ))}
        </RuleContainer>
        {this.props.static ? null : (
          <FilterButtons>
            {isFreePlan && existingOptions.length > 2 ? (
              <Tooltip
                unmountHTMLWhenHide
                html={<TooltipText>Upgrade to add more filters</TooltipText>}
                position="top"
                width="auto"
              >
                <TextButton>
                  <UpgradeIcon />
                  Add a filter
                </TextButton>
              </Tooltip>
            ) : existingOptions.length < 11 ? (
              <TextButton
                onClick={this.addCondition.bind(this, this.props.ruleIndex)}
                onKeyDown={(e) => handleKeyboardClick(e, () => this.addCondition(this.props.ruleIndex))}
                style={{ position: "absolute", top: "-1.5em" }}
                tabIndex="0"
              >
                <FaPlus />
                Add a filter
              </TextButton>
            ) : null}
            {true ? null : isFreePlan ||
              this.props.router.location.pathname.includes("/lp/") ? null : this
                .props.settings ? (
              this.props.ruleIndex ==
              this.props.automationRules.new.filter.length - 1 ? (
                <AltCTA
                  type="button"
                  text="OR"
                  dark
                  icon={<FaPlus />}
                  clickAction={this.addRule}
                />
              ) : null
            ) : this.props.ruleIndex ==
              this.props.feedFilterRules.new.filter.length - 1 ? (
              <AltCTA
                type="button"
                text="OR"
                dark
                icon={<FaPlus />}
                clickAction={this.addRule}
              />
            ) : null}
          </FilterButtons>
        )}
        {this.props.static || this.props.alerts || this.props.data ? null : this
            .props.settings ? (
          this.props.ruleIndex ==
          this.props.automationRules.new.filter.length - 1 ? null : (
            <AddRule>
              <Hr />
              <ButtonContainer>
                <H3 center>OR</H3>
              </ButtonContainer>
              <Hr />
            </AddRule>
          )
        ) : this.props.ruleIndex ==
          this.props.feedFilterRules.new.filter.length - 1 ? null : (
          <AddRule>
            <Hr />
            <ButtonContainer>
              <H3 center>OR</H3>
            </ButtonContainer>
            <Hr />
          </AddRule>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  feedFilterRules: state.feedFilterRules,
  automationRules: state.automationRules,
  dataUser: state.dataUser,
  router: state.router,
  translations: state.translations,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
});

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Rule));
